import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AppContext } from './shared/enums/AppContext.enum';
import { HostApp } from './shared/enums/Host.enum';
import { ApiService } from './shared/services/api.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { StorageService } from './shared/services/storage.service';
import { ToastService } from './shared/services/toast.service';
import { UtilService } from './shared/services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  sessionInitialized: boolean = false;
  authorizationPending: boolean = true;
  authorized: boolean = false;
  hostApp: HostApp;
  appContext: AppContext;
  targetEntityId: string;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private storageService: StorageService,
    private toastService: ToastService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.hostApp = this.getHostAppFromUrl();
    this.appContext = this.getAppContextFromUrl();
    this.targetEntityId = this.getAppTargetEntityIdFromUrl();

    this.configurationService.hostApp = this.hostApp;
    this.configurationService.appContext = this.appContext;
    this.configurationService.targetEntityId = this.targetEntityId;

    this.authorizeSession();
  }

  getHostAppFromUrl(): HostApp {
    return (this.utilService.getUrlQueryParam('hostApp') as HostApp) ?? null;
  }

  getAppContextFromUrl(): AppContext {
    return (this.utilService.getUrlQueryParam('appContext') as AppContext) ?? null;
  }

  getAppTargetEntityIdFromUrl(): string {
    return (this.utilService.getUrlQueryParam('targetEntityId')) ?? null;
  }

  /**
   * Authorize the session by validating the bearer token and initializing the current user
   */
  authorizeSession(): void {
    const token = this.getToken();
    if (token == null) {
      this.authorizationPending = false;

      this.toastService.error('Unauthorized');
      return;
    }

    this.apiService.bearerToken = token;
    this.apiService.sourceApp = this.hostApp || 'REMOTE_ACCESS_PORTAL';

    this.authenticationService.getAndInitializeUserInfo().subscribe({
      complete: () => {
        this.authorized = true;
        this.authorizationPending = false;
      },
      error: () => {
        this.authorizationPending = false;
        this.toastService.error('Unauthorized');
      },
    });
  }

  /**
   * Look for the bearer token either in the "token" query parameter or in localStorage
   * @returns
   */
  getToken(): string {
    return (
      this.utilService.getUrlQueryParam('token') ||
      this.storageService.bearerToken
    );
  }

  handleShowCurrentConfig() {
    this.dialog.open(ConfigDialog, {
      data: {
        sessionId: this.configurationService.sessionId,
        ...this.configurationService.remoteAccessConfig,
      },
    });
  }
}

@Component({
  template: `<h2 mat-dialog-title>Current Configuration</h2>
    <mat-dialog-content class="mat-typography">
      <p *ngIf="!data">No CCU Selected...</p>
      <ul *ngIf="data">
        <li><b>Quality: </b> {{ data.quality }}</li>
        <li><b>Image Type: </b> {{ data.imageType }}</li>
        <li><b>Upload Frequency (ms): </b> {{ data.uploadFrequency }}</li>
        <li><b>Refresh Frequency (ms): </b> {{ data.refreshFrequency }}</li>
        <li><b>Poll Frequency (ms): </b> {{ data.commandPollMillis }}</li>
        <li><b>Session Timeout (min): </b> {{ data.sessionTimeout }}</li>
        <li><b>Offline Timeout (min): </b> {{ data.offlineTimeout }}</li>
      </ul>

      <p *ngIf="data.sessionId"><b>Session ID: </b>{{ data.sessionId }}</p>
    </mat-dialog-content>`,
})
export class ConfigDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
