import { HttpStatusCode } from '@angular/common/http';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import hapticFeedback from 'canvas-confetti';
import { catchError, exhaustMap, interval, Observable, of, Subscription, tap, throwError } from 'rxjs';
import { RemoteAccessClient } from 'src/app/shared/clients/remote-access.client';
import { SiloClient } from 'src/app/shared/clients/silo.client';
import { DeviceStatus } from 'src/app/shared/enums/DeviceStatus.enum';
import { Ccu } from 'src/app/shared/models/Ccu.model';
import { RemoteAccessConfig } from 'src/app/shared/models/RemoteAccessConfig.model';
import { SessionEndEvent } from 'src/app/shared/models/SessionEndEvent.model';
import { SiloCcu } from 'src/app/shared/models/SiloCcu.model';
import { User } from 'src/app/shared/models/User.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'virtual-tablet',
  templateUrl: './virtual-tablet.component.html',
  styleUrls: ['./virtual-tablet.component.scss'],
})
export class VirtualTabletComponent implements OnInit, OnDestroy {
  currentUser: User;
  siteId: string;
  ccuId: string;
  hostApp: string;

  ccuPreSelected: boolean = true;
  availableCcus: Ccu[] = [];

  rasDiscoveredCcus: Ccu[];
  siloCcus: SiloCcu[];

  selectedCcu: Ccu;
  aspectRatio: number = 1.5; // Default starting aspect ratio to use before a CCU is selected

  latestScreenshotTimestampMs: number;
  screenshotSource: SafeResourceUrl; // base64 encoded image data

  showLoader: boolean = false;
  hapticFeedbackEnabled: boolean = false;

  ccuStatusPollingSubscription: Subscription;

  // Manage sessionId through the configurationService
  get sessionId(): string {
    return this.configurationService.sessionId;
  }
  set sessionId(sessionId: string) {
    this.configurationService.sessionId = sessionId;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private remoteAccessClient: RemoteAccessClient,
    private siloClient: SiloClient,
    private toastService: ToastService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;
    this.siteId = this.getSiteIdFromUrl();
    this.ccuId = this.getCcuIdFromUrl();
    this.hostApp = this.configurationService.hostApp;

    this.configurationService.debugModeEnabled =
      this.getDebugModeEnabledFromUrl();

    if (this.ccuId) {
      this.loadPreselectedCcu();
    } else {
      this.ccuPreSelected = false;
    }
  }

  /**
   * Kicks off a job which polls on a fixed interval to refresh the status of the selected CCU.
   * This is initiated once the a preselected CCU is successfully fetched.
   */
  startCcuStatusPolling(): Subscription {
    if (this.ccuStatusPollingSubscription) {
      this.ccuStatusPollingSubscription.unsubscribe();
    }

    return interval(this.configurationService.ccuStatusPollFrequencyMs)
      .pipe(exhaustMap(() => this.refreshCcuStatus()))
      .subscribe();
  }

  getCcuIdFromUrl(): string {
    return this.utilService.getUrlQueryParam('ccuId');
  }

  getSiteIdFromUrl(): string {
    return this.utilService.getUrlQueryParam('siteId');
  }

  getDebugModeEnabledFromUrl(): boolean {
    return this.utilService.getUrlQueryParam('debugMode') == 'true';
  }

  loadPreselectedCcu(): void {
    this.siloClient.getCcuById(this.ccuId).subscribe((siloCcu: SiloCcu) => {
      this.remoteAccessClient
        .getDiscoveredCcuById(this.siteId, this.ccuId)
        .pipe(
          catchError((error) => {
            this.toastService.error('Failed to get CCU details');
  
            return throwError(() => error);
          })
        )
        .subscribe((rasDiscoveredCcu) => {
          this.selectedCcu = this.utilService.mergeSiloAndRasDiscoveredCcu(
            siloCcu,
            rasDiscoveredCcu,
            this.siteId
          );
          this.setAspectRatio();
          this.startCcuStatusPolling();
        });
    });

    this.loadRemoteAccessConfig();
  }

  loadRemoteAccessConfig(): void {
    this.remoteAccessClient
      .getRemoteAccessConfig(this.ccuId)
      .subscribe((remoteAccessConfig: RemoteAccessConfig) => {
        this.configurationService.remoteAccessConfig = remoteAccessConfig;

        // TODO: Until this is supported by the RAS, we need to set a default
        this.configurationService.remoteAccessConfig.commandPollMillis = 100;
      });
  }

  refreshCcuStatus(): Observable<any> {
    if (!this.selectedCcu || !!this.sessionId) {
      return of(null);
    }

    return this.remoteAccessClient
      .getDiscoveredCcuById(this.siteId, this.selectedCcu.ccuId).pipe(
        tap((rasDiscoveredCcu) => {
          if (rasDiscoveredCcu.status !== this.selectedCcu.status) {
            this.selectedCcu.status = rasDiscoveredCcu.status;
          }
        })
      );
  }

  setAspectRatio(): void {
    if (
      !this.selectedCcu?.ccuMetadata?.height ||
      !this.selectedCcu?.ccuMetadata?.width
    ) {
      return;
    }

    this.aspectRatio =
      this.selectedCcu.ccuMetadata.width / this.selectedCcu.ccuMetadata.height;
  }

  canStartSession(): boolean {
    if (!this.selectedCcu) {
      return false;
    }

    return this.authenticationService.canStartSession(this.selectedCcu)
  }

  handleCcuSelectionChange(ccu: Ccu): void {
    this.selectedCcu = ccu;
    this.ccuId = ccu.ccuId;

    this.loadRemoteAccessConfig();
    this.setAspectRatio();
  }

  handleStartSession(): void {
    if (this.selectedCcu.status === DeviceStatus.AVAILABLE) {
      this.startSession()
      return;
    }

    // Confirm with the user before clobbering an active session
    this.dialog.open(SessionOverrideConfirmationDialog, {
      data: {
        selectedCcu: this.selectedCcu,
        currentUser: this.currentUser,
      }
    })
    .afterClosed()
    .subscribe((shouldProceed) => {
      if (shouldProceed) {
        this.overrideActiveSession();
      }
    });
  }

  handleEndSession(sessionEndEvent?: SessionEndEvent): void {
    if (!this.sessionId) {
      return;
    }

    if (sessionEndEvent?.unexpected) {
      this.clearSessionData();
      this.toastService.error('Session terminated unexpectedly.');
      return;
    }

    this.showLoader = true;
    this.remoteAccessClient
      .endRemoteAccessSession(this.sessionId)
      .pipe(
        catchError((error) => {
          this.toastService.error('Failed to end session');
          this.showLoader = false;

          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.clearSessionData();

        this.toastService.success('Session Ended');
          this.showLoader = false;
      });
  }

  handleEngageHapticFeedback(event): void {
    if (event.deltaTime > 5000) {
      this.toastService.success('Haptic feedback enabled');
      this.hapticFeedbackEnabled = true;
    }
  }

  handleHapticFeedback(): void {
    if (this.hapticFeedbackEnabled) {
      hapticFeedback({
        particleCount: 30,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: ['#e5561a'],
      });
      hapticFeedback({
        particleCount: 30,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: ['#e5561a'],
      });
    }
  }

  startSession(): void {
    this.showLoader = true;

    this.remoteAccessClient
      .startRemoteAccessSession(this.siteId, this.ccuId)
      .pipe(
        catchError((error) => {
          const errorMessage = error.status === HttpStatusCode.Conflict 
            ? 'Session already in progress' 
            : 'Failed to start session';

          this.toastService.error(errorMessage);
          this.showLoader = false;

          return throwError(() => error);
        })
      )
      .subscribe((sessionId) => {
        this.sessionId = sessionId;
        this.showLoader = false;
      });
  }

  overrideActiveSession(): void {
    this.showLoader = true;

    // Force-close the current active session by ccuId before starting a new session
    this.remoteAccessClient
      .endRemoteAccessSessionByCcuId(this.ccuId)
      .pipe(
        catchError((error) => {
          this.toastService.error("Failed to end in-progress session.");
          this.showLoader = false;

          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.startSession();
      });
  }

  clearSessionData(): void {
    this.sessionId = null;

    if (this.ccuPreSelected) {
      this.loadPreselectedCcu();
    } else {
      this.ccuId = null;
      this.selectedCcu = null;
    }
  }

  /**
   * Desperately attempt to end the currently open session when navigating away or killing the browser tab
   *
   * @returns
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.handleEndSession();
    return true;
  }

  ngOnDestroy(): void {
    this.handleEndSession();

    this.ccuStatusPollingSubscription?.unsubscribe();
  }
}

@Component({
  template: `
    <h2 mat-dialog-title style="font-weight: bold">
      <i class="fas fa-exclamation-triangle" style="color: var(--warning); margin-right: 8px" aria-hidden="true"></i> 
      Do you wish to continue?
    </h2>
    <mat-dialog-content class="mat-typography">      
      <p>
        <span *ngIf="isSessionHeldByCurrentUser">
          You have an active remote session with {{ data.selectedCcu.ccuMetadata?.name }} which was started at {{ data.selectedCcu.session?.startedAt | date:'h:mm a' }}.<br/>
        </span>
        <span *ngIf="!isSessionHeldByCurrentUser">
          An active session is currently underway for: <b>{{ data.selectedCcu.session?.emailId }}</b>.<br/>
        </span>
        This in-progress session will be closed before a new one is begun.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: end; color: var(--lighter-grey)">
      <button mat-button mat-dialog-close color="primary">CANCEL</button>
      |
      <button mat-button [mat-dialog-close]="true" style="color: var(--primary)">CONTINUE</button>  
    </mat-dialog-actions>`,
})
export class SessionOverrideConfirmationDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedCcu: Ccu, currentUser: User }
  ) {}

  get isSessionHeldByCurrentUser() {
    return this.data.selectedCcu?.session?.emailId === this.data.currentUser?.emailId
  }
}
