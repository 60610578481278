import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * This service is used to communicate with the key capture service to enable or disable key
 * capture based on the send-text component input control focus.  The 'send-text' component
 * will disable key capture when the input control is focused and re-enable key capture when
 * the input control loses focus.  The 'screenshot' component will consume this observable and
 * adjust key capture accordingly.
 */
@Injectable({
  providedIn: 'root'
})
export class SendTextService {
  private dataSubject: Subject<boolean> = new Subject<boolean>();
  data$: Observable<boolean> = this.dataSubject.asObservable();

  constructor() { }

  putKeyCaptureEnabled(enabled: boolean) {
    this.dataSubject.next(enabled);
  }
}