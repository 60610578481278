import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { CcuSelectComponent } from './components/ccu-dropdown/ccu-select.component';
import { ScreenshotComponent, StaleSessionWarningDialog } from './components/screenshot/screenshot.component';
import { SendTextComponent } from './components/send-text/send-text.component';
import { SettingsMenuButtonComponent } from './components/settings-menu-button/settings-menu-button.component';
import { SessionOverrideConfirmationDialog, VirtualTabletComponent } from './components/virtual-tablet/virtual-tablet.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CcuSelectComponent,
    ScreenshotComponent,
    SessionOverrideConfirmationDialog,
    SettingsMenuButtonComponent,
    SendTextComponent,
    StaleSessionWarningDialog,
    VirtualTabletComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,

    // Angular Material
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatSliderModule,
    FormsModule,
  ],
  exports: [VirtualTabletComponent],
  providers: [{ provide: 'environment', useValue: environment }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RemoteAccessPortalModule {}
