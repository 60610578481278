import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { RemoteAccessClient } from 'src/app/shared/clients/remote-access.client';
import { KeysCommand } from 'src/app/shared/models/commands/KeysCommand.class';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SendTextService } from '../../services/send-text.service';
import { PowerBackspaceCommand } from 'src/app/shared/models/commands/PowerBackspaceCommand.class';

const POWER_BACKSPACE_KEY_COUNT = 5;

/**
 * This component will send bulk test to the CCU to be replayed as keystrokes.  Additionally, 
 * this component will send a quantity of backspaces to the CCU to be replayed as backspaces.
 * 
 * These 2 things avoid some significant performance issues with sending individual keystrokes.
 */
@Component({
  selector: 'send-text',
  templateUrl: './send-text.component.html',
  styleUrls: ['./send-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SendTextComponent {
  @Input()
  ccuId: string;

  @Input()
  sessionId: string;

  dataToSend: string = '';
  backspaceCount: number = POWER_BACKSPACE_KEY_COUNT;
  passwordText: boolean = false;
  keyboardEnabled: boolean = false;

  constructor(
    private remoteAccessClient: RemoteAccessClient,
    private toastService: ToastService,
    private sendTextService: SendTextService,
  ) { }

  /**
   * Toggles the keyboard capture subscription on or off.
   */
  toggleKeyboardCapture() {
    this.keyboardEnabled = !this.keyboardEnabled;
    this.sendTextService.putKeyCaptureEnabled(this.keyboardEnabled);
  }

  /**
   * Toggles the password text visibility in the input control.
   */
  togglePasswordText() {
    this.passwordText = !this.passwordText;
  }

  /**
   * It is important to disable key capture when the input is focused so that the user can type
   * without the keys being captured by the key capture service.  Without doing this, input
   * is sent to the CCU as it's typed as well as in bulk.
   */
  onFocus() {
    this.sendTextService.putKeyCaptureEnabled(false);
  }

  /**
   * Restores keystroke capturing when the bulk input field loses focus.
   */
  onBlur() {
    if (this.keyboardEnabled) {
      this.sendTextService.putKeyCaptureEnabled(true);
    }
  }

  /**
   * 
   * @returns Sends the text from the input control to the CCU in the form of a 'keys' command.
   */
  sendText(): void {
    if (this.dataToSend.length === 0) {
      this.toastService.error('Please enter text to send');
      return;
    }

    const keysCommand = new KeysCommand(this.dataToSend.split(''));
    this.handleApply(keysCommand.toCommandString());
  }

  /**
   * Configures and sends a bulk set of backspaces to the CCU to be replayed as backspaces.
   */
  deleteText(): void {
    const powerBackspaceCommand = new PowerBackspaceCommand(this.backspaceCount);
    const command = powerBackspaceCommand.toCommandString();
    this.handleApply(command);
    this.toastService.error('Sending ' + this.backspaceCount + ' backspaces to CCU');

  }

  /**
   * 
   * @param commandString The command string to send to the CCU
   * @returns Sends the command to the CCU and resets the input control.
   */
  handleApply(commandString): void {
    this.remoteAccessClient
      .postCommand(
        this.sessionId,
        this.ccuId,
        commandString,
        new Date().getTime(),
      )
      .pipe(
        catchError((error) => {
          this.toastService.error(`Failed to send text ${this.dataToSend}`);
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.dataToSend = '';
      });
  }
}
