import { BaseCommand } from './BaseCommand';

/**
 * This command will configure and generate the 'powerbackspace' command to be sent to the CCU.
 * 
 * 'powerbackspace' will cause the a bulk set of backspaces to be replayed on the CCU, bulk being
 * controlled by the count field.  To replay 50 backspaces, this will generate 'powerbackspace,50'.
 * The CCU will receive this and replay 50 backspaces.
 */
export class PowerBackspaceCommand implements BaseCommand {
  count: number;

  constructor(count: number) {
    this.count = count;
  }

  toCommandString = () => `powerbackspace,${this.count}`;
}
